import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import Home from "@/views/Premium/HomeView.vue";
import LayoutAuthenticated from "@/layouts/LayoutAuthenticated.vue";
import ProjectWizardStep from "@/models/ProjectWizardStep";

const routes = [
  { path: "/:pathMatch(.*)*", redirect: "/" },
  {
    meta: {
      title: "Wizard",
    },
    path: "/wizard",
    name: "wizard",
    component: () => import("@/components/ProjectWizard/ProjectWizard.vue"),
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Dashboard"
    },
    path: "/",
    redirect: "/organisations",
    name: "dashboard",
    component: () => import("@/layouts/LayoutAuthenticated.vue"),
    children: [

      {
        meta: {

          hideAsideMenu: true,
          title: "Organisations",
        },
        path: "/organisations",
        name: "organisations",
        component: () => import("@/views/Premium/OrganisationsView.vue"),
      },
      {
        meta: {
          title: "Wizard",
          hideAsideMenu: true,
          isFocusModeOn: true
        },
        path: "/project/:organisationConfigId/:projectConfigId/wizard",
        name: "projectWizard",
        component: () => import("@/components/ProjectWizard/ProjectWizard.vue"),
      },
      {
        meta: {
          title: "Wizard",

          hideAsideMenu: true,
          isFocusModeOn: true,
        },
        path: "/project/:organisationConfigId/:projectConfigId/wizard/infrastructureconfiguration/:infrastructureConfigurationId",
        name: "deployWizardStep",
        component: () => import("@/components/ProjectWizard/ProjectWizard.vue"),
      },
      {
        meta: {
          title: "Wizard",
          hideAsideMenu: true,
          isFocusModeOn: true,
        },
        path: "/project/:organisationConfigId/:projectConfigId/wizard/infrastructureconfiguration",
        name: "createInfrastructureConfigurationWizardStep",
        component: () => import("@/components/ProjectWizard/ProjectWizard.vue"),
      },

      {
        meta: {
          title: "Wizard",
          hideAsideMenu: true,
          isFocusModeOn: true,
        },
        path: "/project/:organisationConfigId/:projectConfigId/wizard/aws/configure",
        name: "configureAwsWizardStep",
        component: () => import("@/components/ProjectWizard/ProjectWizard.vue"),
      },


      {
        meta: {
          title: "Wizard",
          isFocusModeOn: true,
        },
        path: "/project/:organisationConfigId/:projectConfigId/wizard/release",
        name: "configureReleaseTargets",
        component: () => import("@/components/ProjectWizard/ProjectWizard.vue"),
      },



      {
        meta: {
          title: "Wizard",

          hideAsideMenu: true,
          isFocusModeOn: true,
        },
        path: "/project/:organisationConfigId/:projectConfigId/wizard/connections",
        name: "configureConnectionsStep",
        component: () => import("@/components/ProjectWizard/ProjectWizard.vue"),
      },


      {
        meta: {
          title: "Wizard",
          hideAsideMenu: true,
          isFocusModeOn: true,
        },
        path: "/dashboard/wizard",
        name: "dashboardWizard",
        component: () => import("@/components/ProjectWizard/ProjectWizard.vue"),
      },



      {
        meta: {
          title: "Create Organisation",
          hideAsideMenu: true,
        },
        path: "/organisation/create",
        name: "createOrganisation",
        component: () => import("@/views/Premium/organisation/CreateOrganisationView.vue"),
      },

      {
        meta: {
          title: "Organisation",
          hideAsideMenu: true,
        },
        path: "/organisation/:organisationConfigId",
        name: "organisation",
        component: () => import("@/views/Premium/OrganisationView.vue"),
      },

      {
        meta: {
          title: "Create Project",
        },
        path: "/organisation/:organisationConfigId/project/create",
        name: "createProject",
        component: () => import("@/views/Premium/project/CreateProjectView.vue"),
      },

      {
        meta: {
          title: "Organisation",
        },
        path: "/organisation/:organisationConfigId",
        name: "organisation",
        redirect: to => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.

          //+ to.path.endsWith('/')?'':'/'
          return { path: to.path +  (to.path.endsWith('/')?'':'/') + 'projects'  }
        },
        component: () => import("@/views/Premium/OrganisationView.vue"),
        children: [
          {
            meta: {
              title: "Settings",
              organisationGroup: 'settings',
              hasAsideMenu: true
            },
            redirect: to => {
              // the function receives the target route as the argument
              // we return a redirect path/location here.

              //+ to.path.endsWith('/')?'':'/'
              return { path: to.path +  (to.path.endsWith('/')?'':'/') + 'aws-connections'  }
            },
            path: "settings",
            name: "organisationSettings",
            components : {
              default: () => import("@/views/Premium/organisation/OrganisationSettingsView.vue")
            },
            children: [
              {
                meta: {
                  title: "Members",
                  organisationGroup: 'settings',
                  hasAsideMenu: true
                },
                path: "members",
                name: "organisationMembersSettings",
                components : {
                  default: () => import("@/views/Premium/OrganisationMembersView.vue"),
                  AsideMenuList: () => import("@/components/AsideMenu/OrganisationSettingsMenu.vue")
                },
              },
              {
                meta: {
                  title: "Settings",
                  organisationGroup: 'settings',
                  hasAsideMenu: true
                },
                path: "aws-connections",
                name: "organisationAwsConnectionsSettings",
                components : {
                  default: () => import("@/views/Premium/settings/AwsConnectionsView.vue"),
                  AsideMenuList: () => import("@/components/AsideMenu/OrganisationSettingsMenu.vue")
                },
              },
              {
                meta: {
                  title: "Git Connection Settings",
                  organisationGroup: 'settings',
                  hasAsideMenu: true
                },
                path: "git-connections",
                name: "gitConnectionsSettings",
                components : {
                  default: () => import("@/views/Premium/settings/GitConnectionSettingsView.vue"),
                  AsideMenuList: () => import("@/components/AsideMenu/OrganisationSettingsMenu.vue")
                },
              }
            ]
          },
          {
            meta: {
              title: "Projects",
              organisationGroup: 'projects',
              hideAsideMenu: true,
            },
            path: "projects",
            name: "organisationProjects",
            components : {
              default: () => import("@/views/Premium/OrganisationProjectsView.vue")
            },
          }]
      },


      {
        meta: {
          title: "Project",
        },
        path: "/project/:organisationConfigId/:projectConfigId",
        name: "project",
        redirect: to => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.

          //+ to.path.endsWith('/')?'':'/'
          return { path: to.path +  (to.path.endsWith('/')?'':'/') + 'manage'  }
        },
        components : {
          default: () => import("@/views/Premium/ProjectView.vue"),
        },

        children: [
          {
            path: "cost",
            children: [
              {
                path: "",
                // default: () => import("@/views/Premium/cost/CostView.vue"),
                components : {
                  default: () => import("@/views/Premium/cost/CostsView.vue"),
                  // AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
                },
              }
            ]
          },
          {
            meta: {
              title: "Settings",
              projectSettingsGroup: 'settings',
              projectGroup: 'settings'
            },
            path: "settings",
            name: "projectsSettings",
            redirect: to => {
              // the function receives the target route as the argument
              // we return a redirect path/location here.

              //+ to.path.endsWith('/')?'':'/'
              return { path: to.path +  (to.path.endsWith('/')?'':'/') + 'environments'  }
            },
          },{
            meta: {
              title: "Environments Settings",
              projectSettingsGroup: 'environmentsSettings',
              projectGroup: 'settings'
            },
            path: "settings/environments",
            name: "projectEnvironmentsSettings",
            components : {
              default: () => import("@/views/Premium/settings/EnvironmentSettingsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            },
          },{
            meta: {
              title: "Create Environment",
              projectSettingsGroup: 'environmentsSettings',
              projectGroup: 'settings'
            },
            path: "settings/environment/create",
            name: "createProjectEnvironments",
            components : {
              default: () => import("@/views/Premium/settings/CreateEnvironmentView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            },
          },{
            meta: {
              title: "Create Service",
              projectSettingsGroup: 'servicesSettings',
              projectGroup: 'settings'
            },
            path: "settings/service/create",
            name: "createProjectService",
            components : {
              default: () => import("@/views/Premium/settings/CreateServiceView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            },
          },
          {
            meta: {
              title: "User Groups",
              projectSettingsGroup: 'access',
              projectGroup: 'settings'
            },
            path: "settings/access/usergroups",
            name: "userGroups",
            components: {
              default: () => import("@/views/Premium/settings/UserGroupsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            }
          },
          {
            meta: {
              title: 'Create User Group',
              projectSettingsGroup: 'access',
              projectGroup: 'settings'
            },
            path: "settings/access/usergroup/create",
            name: "createUserGroup",
            components: {
              default: () => import("@/views/Premium/settings/CreateUserGroupView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            }
          }, {
            meta: {
              title: 'User Group',
              projectSettingsGroup: 'access',
              projectGroup: 'settings'
            },
            path: "settings/access/usergroup/:userGroupUuid",
            name: "userGroup",
            components: {
              default: () => import("@/views/Premium/settings/UserGroupView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            }
          },
          {
            meta: {
              title: "AWS Settings",
              projectSettingsGroup: 'awsSettings',
              projectGroup: 'settings'
            },
            path: "settings/aws",
            name: "projectAwsSettings",
            components : {
              default: () => import("@/views/Premium/settings/AwsSettingsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            },
          },
          {
            meta: {
              title: "Webhook Settings",
              projectSettingsGroup: 'webhookSettings',
              projectGroup: 'settings'
            },
            path: "settings/webhooks",
            name: "projectWebhookSettings",
            components : {
              default: () => import("@/views/Premium/settings/WebhookSettingsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            },
          },
          {
            meta: {
              title: "Alarms",
              projectSettingsGroup: 'alarmSettings',
              projectGroup: 'settings'
            },
            path: "settings/alarms",
            name: "projectAlarmSettings",
            components : {
              default: () => import("@/views/Premium/settings/ConfigureAlarmSettingsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            },
          },
          {
            meta: {
              title: "Configure AWS Settings",
              projectSettingsGroup: 'awsSettings',
              projectGroup: 'settings'
            },
            path: "settings/aws/configure",
            name: "configureProjectAwsSettings",
            components : {
              default: () => import("@/views/Premium/settings/AwsConfigurationSettingsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            },
          },{
            meta: {
              title: "Services Settings",
              projectSettingsGroup: 'servicesSettings',
              projectGroup: 'settings'
            },
            path: "settings/services",
            name: "projectServicesSettings",
            components : {
              default: () => import("@/views/Premium/settings/ServicesSettingsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ProjectSettingsMenu.vue")
            },
          },   {
            meta: {
              title: "Services",
              projectGroup: 'services',
              servicesGroup: 'services'
            },
            path: "services",
            name: "projectServices",
            components : {
              default: () => import("@/views/Premium/ServicesView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ServicesMenu.vue")
            },
          },

          {
            meta: {
              title: "Infrastructure",
              projectGroup: 'infrastructure',
            },
            redirect: to  => ({
              name: 'infrastructureOverview'
            }),
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure",
            name: "infrastructure",
            components : {
              default: () => import("@/views/Premium/project/InfrastructureView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },
          {
            meta: {
              title: "Environment Infrastructure",
              projectGroup: 'infrastructure',
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/environment/:projectEnvironmentUuid",
            name: "environmentInfrastructure",
            components : {
              default: () => import("@/views/Premium/infrastructure/ProjectEnvironmentInfrastructureView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },
          {
            meta: {
              title: "Infrastructure Overview",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'overview'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/overview",
            name: "infrastructureOverview",
            components : {
              default: () => import("@/views/Premium/infrastructure/InfrastructureOverviewView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },
          {
            meta: {
              title: "Connections",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'connections'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/connections",
            name: "infrastructureConnections",
            components : {
              default: () => import("@/views/Premium/infrastructure/InfrastructureConnectionsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },
          {
            meta: {
              title: "Manage",
              projectGroup: 'manage',
              hideAsideMenu: true
            },
            path: "/project/:organisationConfigId/:projectConfigId/manage",
            name: "manage",
            components : {
              default: () => import("@/views/Premium/architecture/ArchitectureView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/Architecture/ArchitectureMenu.vue")
            },
          },
          {
            meta: {
              title: "AI",
              projectGroup: 'ai',
              hideAsideMenu: true
            },
            path: "/project/:organisationConfigId/:projectConfigId/manage/ai/6d597859-936a-4d07-bb9c-fe529de7c751",
            name: "ai",
            components : {
              default: () => import("@/views/Premium/ai/AiView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/Architecture/ArchitectureMenu.vue")
            },
          },
          {
            meta: {
              title: "Configurations",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'configurations'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/configurations",
            name: "infrastructureConfigurations",
            components : {
              default: () => import("@/views/Premium/infrastructure/InfrastructureConfigurationsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },


          {
            meta: {
              title: "Configuration",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'configurations'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/configuration/:infrastructureConfigurationId",
            name: "infrastructureConfigurationView",
            components : {
              default: () => import("@/views/Premium/infrastructure/InfrastructureConfigurationView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },


          {
            meta: {
              title: "Inspect Configuration",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'configurations'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/configuration/:infrastructureConfigurationId/inspect",
            name: "infrastructureConfigurationInspectView",
            components : {
              default: () => import("@/views/Premium/infrastructure/configuration/InfrastructureConfigurationInspectView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },

          {
            meta: {
              title: "Create Configuration",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'configurations'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/configuration/create",
            name: "createConfiguration",
            components : {
              default: () => import("@/views/Premium/infrastructure/configuration/CreateConfigurationView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },

          {
            meta: {
              title: "Revisions",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'revisions'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/revisions",
            name: "infrastructureRevisions",
            components : {
              default: () => import("@/views/Premium/infrastructure/InfrastructureRevisionsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },

          {
            meta: {
              title: "Previews",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'previews'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/previews",
            name: "infrastructurePreviews",
            components : {
              default: () => import("@/views/Premium/infrastructure/PreviewEnvironmentsView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },
          {
            meta: {
              title: "Create Preview Environment",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'previews'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/preview/create",
            name: "createInfrastructurePreview",
            components : {
              default: () => import("@/views/Premium/environment/CreatePreviewEnvironmentView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },

         /* {
            meta: {
              title: "Revision",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'revisions'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/revision/ai/78de6000-69de-4dfd-a944-836414394417",
            name: "infrastructureRevisionUi",
            components : {
              default: () => import("@/views/Premium/infrastructure/InfrastructureRevisionUIView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },*/

          {
            meta: {
              title: "Revision",
              projectGroup: 'infrastructure',
              infrastructureGroup: 'revisions'
            },
            path: "/project/:organisationConfigId/:projectConfigId/infrastructure/revision/:infrastructureRevisionUuid",
            name: "infrastructureRevision",
            components : {
              default: () => import("@/views/Premium/infrastructure/InfrastructureRevisionView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/InfrastructureMenu.vue")
            },
          },

          {
            meta: {
              title: "Overview",
              projectGroup: 'services',
              servicesGroup: 'overview'
            },
            path: "/project/:organisationConfigId/:projectConfigId/services/overview",
            name: "overview",
            components : {
              default: () => import("@/views/Premium/services/ServicesOverviewView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ServicesMenu.vue")
            },
          },

          {
            meta: {
              title: "Service",
              projectGroup: 'services'
            },
            path: "/project/:organisationConfigId/:projectConfigId/service/:serviceConfigId",
            name: "service",

            redirect: to => {
              // the function receives the target route as the argument
              // we return a redirect path/location here.

              //+ to.path.endsWith('/')?'':'/'
              return { path: to.path +  (to.path.endsWith('/')?'':'/') + 'overview'  }
            },
            components : {
              default: () => import("@/views/Premium/ServiceView.vue"),
              AsideMenuList: () => import("@/components/AsideMenu/ServiceMenu.vue")
            },

            children: [
              {
                meta: {
                  title: "Packages",
                  projectGroup: 'services',
                  serviceGroup: 'packages'
                },
                path: "/project/:organisationConfigId/:projectConfigId/service/:serviceConfigId/packages",
                name: "packages",
                components : {
                  default: () => import("@/views/Premium/services/ServicePackagesView.vue"),
                },
              },
              {
                meta: {
                  title: "Package",
                  projectGroup: 'services',
                  serviceGroup: 'packages'
                },
                path: "/project/:organisationConfigId/:projectConfigId/service/:serviceConfigId/package/:servicePackageUuid",
                name: "package",
                components : {
                  default: () => import("@/views/Premium/services/ServicePackageView.vue"),
                },
              },


              {
                meta: {
                  title: "Create Release",
                  projectGroup: 'services',
                  serviceGroup: 'packages'
                },
                path: "/project/:organisationConfigId/:projectConfigId/service/:serviceConfigId/package/:servicePackageUuid/release/create",
                name: "createRelease",
                components : {
                  default: () => import("@/views/Premium/services/CreateServiceReleaseView.vue"),
                },
              },

              {
                meta: {
                  title: "Release",
                  projectGroup: 'services',
                  serviceGroup: 'releases'
                },
                path: "/project/:organisationConfigId/:projectConfigId/service/:serviceConfigId/release/:serviceReleaseUuid",
                name: "release",
                components : {
                  default: () => import("@/views/Premium/services/ServiceReleaseView.vue"),
                },
              },


              {
                meta: {
                  title: "Releases",
                  projectGroup: 'services',
                  serviceGroup: 'releases'
                },
                path: "/project/:organisationConfigId/:projectConfigId/service/:serviceConfigId/releases",
                name: "releases",
                components : {
                  default: () => import("@/views/Premium/services/ServiceReleasesView.vue"),
                },
              },

              {
                meta: {
                  title: "Overview",
                  projectGroup: 'services',
                  serviceGroup: 'overview'
                },
                path: "/project/:organisationConfigId/:projectConfigId/service/:serviceConfigId/overview",
                name: "serviceOverview",
                components : {
                  default: () => import("@/views/Premium/services/ServicesOverviewView.vue"),
                },
              },

              {
                meta: {
                  title: "Deploys",
                  projectGroup: 'services',
                  serviceGroup: 'deploys'
                },
                path: "/project/:organisationConfigId/:projectConfigId/service/:serviceConfigId/deploys",
                name: "deploys",
                components : {
                  default: () => import("@/views/Premium/services/ServiceDeploysView.vue"),
                },
              },
              {
                meta: {
                  title: "Deploy",
                  projectGroup: 'services',
                  serviceGroup: 'deploys'
                },
                path: "/project/:organisationConfigId/:projectConfigId/service/:serviceConfigId/deploy/:serviceDeployUuid",
                name: "deploy",
                components : {
                  default: () => import("@/views/Premium/services/ServiceDeployView.vue"),
                },
              },

            ]
          },





        ]

      },



    ]
  },
  {
    path: "/dashboard",
    redirect: "/",
  },
  {
    meta: {
      title: "Tables",
    },
    path: "/tables-and-lists",
    name: "tables",
    component: () => import("@/views/Premium/TablesListsView.vue"),
  },

  {
    meta: {
      title: "Forms Base",
    },
    path: "/forms",
    name: "forms",
    component: () => import("@/views/FormsView.vue"),
  },
  {
    meta: {
      title: "Forms Advanced",
    },
    path: "/forms-advanced",
    name: "forms-advanced",
    component: () => import("@/views/Premium/FormsView.vue"),
  },
  {
    meta: {
      title: "Profile",
    },
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Premium/ProfileView.vue"),
  },
  {
    meta: {
      title: "Ui Base",
    },
    path: "/ui",
    name: "ui",
    component: () => import("@/views/UiView.vue"),
  },
  {
    meta: {
      title: "Ui Advanced",
    },
    path: "/ui-advanced",
    name: "ui-advanced",
    component: () => import("@/views/Premium/UiView.vue"),
  },
  {
    meta: {
      title: "Sidebar styles",
    },
    path: "/styles",
    name: "style",
    component: () => import("@/views/Premium/StyleView.vue"),
  },
  {
    meta: {
      title: "Pricing layout",
    },
    path: "/pricing",
    name: "pricing",
    component: () => import("@/views/Premium/PricingView.vue"),
  },
  {
    meta: {
      title: "Login",
    },
    path: "/login",
    name: "login",
    component: () => import("@/views/Premium/LoginView.vue"),
  },
  {
    meta: {
      title: "Sandbox",
    },
    path: "/sandbox",
    name: "sandbox",
    component: () => import("@/views/Premium/SandboxView.vue"),
  },
  {
    meta: {
      title: "Sign up",
    },
    path: "/signup",
    name: "signup",
    component: () => import("@/views/Premium/SignupView.vue"),
  },
  {
    meta: {
      title: "Remind Password",
    },
    path: "/remind",
    name: "remind",
    component: () => import("@/views/Premium/RemindView.vue"),
  },
  {
    meta: {
      title: "Error",
    },
    path: "/error",
    name: "error",
    component: () => import("@/views/ErrorView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: to.hash.indexOf('main') !== -1? 47: 120
      }
    }
  },
  scrodllBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

export default router;
