import axios from "axios";

import {useAuthStore} from "@/stores/auth";
import router from "../router";
import {useMainStore} from "@/stores/main";
import {getActivePinia} from "pinia";
import {useSnackBarStore} from "@/stores/snackBar";

const nonInterceptedInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});



async function refreshAccessToken(originalConfig){
  originalConfig._retry = true;

  try {
    // http cookie
    const rs = await nonInterceptedInstance.post("/auth/user/refresh",{
    }, {
      withCredentials: true
    });

    if(rs.status !== 200){
      console.dir("pushing to login")
      clearAuthData()
      //await router.push("/login")
      window.location = "/login"
      return
    }

    const { accessToken, user, refreshToken, projects, organisations } = rs.data;

    useAuthStore().accessToken = accessToken;
    useAuthStore().user = user;
    useAuthStore().refreshToken = refreshToken;
    useMainStore().projects = projects;
    useMainStore().organisations = organisations;

    return axiosInstance(originalConfig);
  } catch (error) {
    if(error.response && error.response.status !== 200){
      console.dir("pushing to login")
      clearAuthData()
      //await router.push("/login")
      window.location = "/login"
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
}


axiosInstance.interceptors.request.use(
  (config) => {

    const authStore = useAuthStore();
    const token = authStore.accessToken;
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
    }else{
      return refreshAccessToken(config)
    }

    return config;
  },
  (error) => {
    if(error.response && error.response.globalErrors && error.response.globalErrors.length > 0){
      useSnackBarStore().pushMessage(
        error.response.globalErrors[0].error,
        'warning',
      );
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;


    if (!originalConfig.url.startsWith("auth/user") && err.response) {
      // Access Token was expired
      console.dir("inside")

      if((err.response.status === 401 || err.response.status === 403) && originalConfig._retry){
        clearAuthData()
        //await router.push("/login")
        window.location = "/login"
        return
      }

      if ((err.response.status === 401 || err.response.status === 403) && !originalConfig._retry) {
        return refreshAccessToken(originalConfig)
      }
    }

    if(err.response && err.response.data.globalErrors && err.response.data.globalErrors.length > 0){
      useSnackBarStore().pushMessage(
        err.response.data.globalErrors[0].error,
        'warning',
      );
    }

    return Promise.reject(err.response.data);
  }
);

function clearAuthData(){
  const pinia = getActivePinia()

  pinia._s.forEach((store) => store.$reset());

  localStorage.clear();
  sessionStorage.clear();
  const rootDomain = window.location.hostname.split('.').slice(-2).join('.');
  document.cookie = `refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${rootDomain};`;
}

export default axiosInstance;


